// polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

// Vue
import Vue from "vue";
import TurbolinksAdapter from "vue-turbolinks";

// Rails
// for method: :delete links and data-confirm, and so on
import Rails from "@rails/ujs"
Rails.start()

// our css and font awesome icons
import "../css/application.scss";
import "@fortawesome/fontawesome-free/js/all";

// required if not using app/assets/javascript/application.js
// if using app/assets/javascript/application.js it should be loaded before
if (typeof Turbolinks === "undefined") {
  var Turbolinks = require("turbolinks");
  Turbolinks.start();
}

Vue.use(TurbolinksAdapter);